import {
  USDC_DECIMALS,
  USDT_DECIMALS,
  WBTC_DECIMALS,
  WEI_DECIMALS,
} from '@lyra/core/constants/contracts'
import { Address } from 'viem'

import { DepositNetwork } from './chains'
import { isTestnet } from './env'
import { MarketId } from './markets'
import { EBTC_DEPOSIT_ADDRESS_ALLOWLIST } from './walletLists'

// Start 23/10 8:00 UTC due to feed issues
const SDAI_START_TIMESTAMP = 1729630800

// Supported Lyra Exchange collateral
export enum CollateralId {
  USDC = 'USDC',
  ETH = 'ETH',
  WSTETH = 'WSTETH',
  BTC = 'BTC',
  DAI = 'DAI',
  SDAI = 'SDAI',
  USDE = 'USDE',
  SUSDE = 'SUSDE',
  USDT = 'USDT',
  WEETH = 'WEETH',
  RSWETH = 'RSWETH',
  RSETH = 'RSETH',
  LBTC = 'LBTC',
  EBTC = 'EBTC',
  CBBTC = 'CBBTC',
  OP = 'OP',
  DEUSD = 'DEUSD',
  SOLVBTC = 'SOLVBTC',
  SOLVBTCBBN = 'SOLVBTCBBN',
  DRV = 'DRV',
  STDRV = 'STDRV',
}

// Lyra chain ERC20
export enum TokenId {
  USDC = 'USDC',
  ETH = 'ETH',
  WSTETH = 'wstETH',
  WBTC = 'WBTC',
  DAI = 'DAI',
  SDAI = 'SDAI',
  USDE = 'USDE',
  SUSDE = 'SUSDE',
  USDT = 'USDT',
  WEETH = 'WEETH',
  RSWETH = 'RSWETH',
  RSETH = 'RSETH',
  LBTC = 'LBTC',
  EBTC = 'EBTC',
  CBBTC = 'CBBTC',
  OP = 'OP',
  DEUSD = 'DEUSD',
  SOLVBTC = 'SOLVBTC',
  SOLVBTCBBN = 'SOLVBTCBBN',
  DRV = 'DRV',
  STDRV = 'STDRV',
}

// Deposit tokens
export enum DepositTokenId {
  USDC = 'USDC',
  DRV = 'DRV',
  USDCe = 'USDC.e',
  USDT = 'USDT',
  ETH = 'ETH',
  WETH = 'WETH',
  WSTETH = 'wstETH',
  WBTC = 'WBTC',
  WEETH = 'WEETH',
  LBTC = 'LBTC',
  RSWETH = 'RSWETH',
  RSETH = 'RSETH',
  DAI = 'DAI',
  SDAI = 'SDAI',
  USDE = 'USDE',
  SUSDE = 'SUSDE',
  EBTC = 'EBTC',
  CBBTC = 'CBBTC',
  OP = 'OP',
  DEUSD = 'DEUSD',
  SOLVBTC = 'SOLVBTC',
  SOLVBTCBBN = 'SOLVBTCBBN',
}

// Currencies can be markets (e.g. SOL) or collaterals (e.g. LBTC)
export type CurrencyId = MarketId | CollateralId

// Join all token enums for e.g. rendering token icons
export type TokenishId = TokenId | CurrencyId | DepositTokenId | CollateralId

// TODO: @earthtojake use PartialToken for all token address configs
export type PartialToken = {
  address: Address
  symbol: string
  decimals: number
}

export type TokenConfig = {
  symbol: string
  name: string
  tokenId: TokenId
  marketId?: MarketId
  collateralId: CollateralId
  isDollarFormat: boolean
  decimals: number
  // Collateral
  isActive: boolean
  isUnlisted?: boolean
  isNonTransferrable?: boolean
  isNonBridgeable?: boolean
  isLayerZero?: boolean
  // Spot market
  spot?: {
    isActive: boolean
    isUnlisted?: boolean
  }
  yield?: {
    copy: string
    pointsOfferShort?: string
  }
  // Hide market data before startTimestamp
  startTimestamp?: number
}

export type CollateralData = {
  collateralId: CollateralId
  spotPrice: number
  spotPrice24hAgo: number
  spotPrice24hChange: number
  spotPrice24hPctChange: number
}

export type Collateral = CollateralData & TokenConfig

export const tokenConfig: Record<TokenId, TokenConfig> = {
  [TokenId.ETH]: {
    symbol: 'ETH',
    name: 'Ethereum',
    tokenId: TokenId.ETH,
    collateralId: CollateralId.ETH,
    isDollarFormat: false,
    decimals: WEI_DECIMALS,
    isActive: true,
    spot: {
      isActive: true,
    },
  },
  [TokenId.WBTC]: {
    symbol: 'WBTC',
    name: 'Wrapped BTC',
    tokenId: TokenId.WBTC,
    collateralId: CollateralId.BTC,
    isDollarFormat: false,
    decimals: WBTC_DECIMALS,
    isActive: true,
    spot: {
      isActive: true,
    },
  },
  [TokenId.USDC]: {
    symbol: 'USDC',
    name: 'USD Coin',
    tokenId: TokenId.USDC,
    collateralId: CollateralId.USDC,
    isDollarFormat: true,
    decimals: USDC_DECIMALS,
    isActive: true,
    yield: {
      copy: "Earning USDC interest on Derive's native lending market",
    },
  },
  [TokenId.USDT]: {
    symbol: 'USDT',
    name: 'Tether USDt',
    tokenId: TokenId.USDT,
    collateralId: CollateralId.USDT,
    isDollarFormat: true,
    decimals: USDT_DECIMALS,
    isActive: true,
    spot: {
      isActive: true,
    },
  },
  [TokenId.WSTETH]: {
    symbol: 'wstETH',
    name: 'Wrapped stETH',
    tokenId: TokenId.WSTETH,
    collateralId: CollateralId.WSTETH,
    isDollarFormat: false,
    decimals: WEI_DECIMALS,
    isActive: true,
    yield: {
      copy: 'Earning ETH staking rewards.',
    },
    spot: {
      isActive: true,
    },
  },
  [TokenId.WEETH]: {
    symbol: 'weETH',
    name: 'Wrapped eETH',
    tokenId: TokenId.WEETH,
    collateralId: CollateralId.WEETH,
    isDollarFormat: false,
    decimals: WEI_DECIMALS,
    isActive: true,
    yield: {
      copy: 'Earning EigenLayer points, a 4x boost on EtherFi points and ETH staking rewards.',
      pointsOfferShort: '4x EtherFi',
    },
    spot: {
      isActive: true,
    },
  },
  [TokenId.RSWETH]: {
    symbol: 'rswETH',
    name: 'Restaked Swell ETH',
    tokenId: TokenId.RSWETH,
    collateralId: CollateralId.RSWETH,
    isDollarFormat: false,
    decimals: WEI_DECIMALS,
    isActive: true,
    spot: {
      isActive: true,
    },
  },
  [TokenId.RSETH]: {
    symbol: 'rsETH',
    name: 'Kelp ETH',
    tokenId: TokenId.RSETH,
    collateralId: CollateralId.RSETH,
    isDollarFormat: false,
    decimals: WEI_DECIMALS,
    isActive: true,
    yield: {
      copy: 'Earning EigenLayer points, a 2x boost on Kernel Points and ETH staking rewards.',
      pointsOfferShort: '2x Miles',
    },
    spot: {
      isActive: true,
    },
  },
  [TokenId.DAI]: {
    symbol: 'DAI',
    name: 'DAI',
    tokenId: TokenId.DAI,
    collateralId: CollateralId.DAI,
    isDollarFormat: true,
    decimals: WEI_DECIMALS,
    isActive: true,
    spot: {
      isActive: true,
    },
  },
  [TokenId.SDAI]: {
    symbol: 'sDAI',
    name: 'Savings DAI',
    tokenId: TokenId.SDAI,
    collateralId: CollateralId.SDAI,
    isDollarFormat: false,
    decimals: WEI_DECIMALS,
    isActive: true,
    yield: {
      copy: 'Earning sDAI savings rate.',
    },
    spot: {
      isActive: true,
    },
    startTimestamp: SDAI_START_TIMESTAMP,
  },
  [TokenId.USDE]: {
    symbol: 'USDe',
    name: 'Ethena USDe',
    tokenId: TokenId.USDE,
    collateralId: CollateralId.USDE,
    isDollarFormat: true,
    decimals: WEI_DECIMALS,
    yield: {
      copy: 'Earning 20x Sats boost.',
      pointsOfferShort: '20x Sats',
    },
    spot: {
      isActive: true,
    },
    isActive: true,
  },
  [TokenId.SUSDE]: {
    symbol: 'sUSDe',
    name: 'Ethena Staked USDe',
    tokenId: TokenId.SUSDE,
    collateralId: CollateralId.SUSDE,
    isDollarFormat: false,
    decimals: WEI_DECIMALS,
    yield: {
      copy: 'Earning 5x Sats boost and sUSDe staking yield.',
      pointsOfferShort: '5x Sats',
    },
    isActive: true,
    spot: {
      isActive: true,
    },
  },
  [TokenId.LBTC]: {
    symbol: 'LBTC',
    name: 'Lombard Staked Bitcoin',
    tokenId: TokenId.LBTC,
    collateralId: CollateralId.LBTC,
    isDollarFormat: false,
    decimals: 8,
    yield: {
      copy: 'Earning Babylon points and a 2x boost on Lombard Lux.',
      pointsOfferShort: '2x Lux',
    },
    isActive: true,
    spot: {
      isActive: true,
    },
  },
  [TokenId.CBBTC]: {
    symbol: 'cbBTC',
    name: 'Coinbase Wrapped BTC',
    tokenId: TokenId.CBBTC,
    collateralId: CollateralId.CBBTC,
    isDollarFormat: false,
    decimals: 8,
    isActive: true,
    spot: {
      isActive: true,
    },
  },
  [TokenId.EBTC]: {
    symbol: 'eBTC',
    name: 'Ether.fi Bitcoin',
    tokenId: TokenId.EBTC,
    collateralId: CollateralId.EBTC,
    isDollarFormat: false,
    decimals: 8,
    isActive: true,
    isUnlisted: true,
  },

  [TokenId.OP]: {
    symbol: 'OP',
    name: 'Optimism',
    tokenId: TokenId.OP,
    collateralId: CollateralId.OP,
    isDollarFormat: false,
    decimals: WEI_DECIMALS,
    isActive: true,
    spot: {
      isActive: true,
    },
  },
  [TokenId.DEUSD]: {
    symbol: 'deUSD',
    name: 'Elixir deUSD',
    tokenId: TokenId.DEUSD,
    collateralId: CollateralId.DEUSD,
    isDollarFormat: true,
    decimals: WEI_DECIMALS,
    isActive: false,
    spot: {
      isActive: false,
    },
  },
  [TokenId.SOLVBTC]: {
    symbol: 'SolvBTC',
    name: 'Solv BTC',
    tokenId: TokenId.SOLVBTC,
    collateralId: CollateralId.SOLVBTC,
    isDollarFormat: false,
    decimals: WEI_DECIMALS,
    isActive: true,
    spot: {
      isActive: true,
    },
  },
  [TokenId.SOLVBTCBBN]: {
    symbol: 'SolvBTCBBN',
    name: 'Solv BTC Babylon',
    tokenId: TokenId.SOLVBTCBBN,
    collateralId: CollateralId.SOLVBTCBBN,
    isDollarFormat: false,
    decimals: WEI_DECIMALS,
    isActive: true,
    spot: {
      isActive: true,
    },
  },
  [TokenId.DRV]: {
    symbol: 'DRV',
    name: 'Derive',
    tokenId: TokenId.DRV,
    decimals: WEI_DECIMALS,
    isActive: true,
    isDollarFormat: false,
    collateralId: CollateralId.DRV,
    spot: {
      isActive: true,
    },
    isLayerZero: true,
  },
  [TokenId.STDRV]: {
    symbol: 'stDRV',
    name: 'Staked Derive',
    tokenId: TokenId.STDRV,
    decimals: WEI_DECIMALS,
    isActive: true,
    isDollarFormat: false,
    collateralId: CollateralId.STDRV,
    isNonTransferrable: true,
    isNonBridgeable: true,
    spot: {
      isActive: false,
    },
    isLayerZero: true,
  },
}

export const collateralConfig: Record<CollateralId, TokenConfig> = {
  [CollateralId.ETH]: tokenConfig[TokenId.ETH],
  [CollateralId.BTC]: {
    ...tokenConfig[TokenId.WBTC],
    symbol: 'BTC',
  },
  [CollateralId.USDC]: tokenConfig[TokenId.USDC],
  [CollateralId.USDT]: tokenConfig[TokenId.USDT],
  [CollateralId.WSTETH]: tokenConfig[TokenId.WSTETH],
  [CollateralId.WEETH]: tokenConfig[TokenId.WEETH],
  [CollateralId.RSWETH]: tokenConfig[TokenId.RSWETH],
  [CollateralId.RSETH]: tokenConfig[TokenId.RSETH],
  [CollateralId.DAI]: tokenConfig[TokenId.DAI],
  [CollateralId.SDAI]: tokenConfig[TokenId.SDAI],
  [CollateralId.USDE]: tokenConfig[TokenId.USDE],
  [CollateralId.SUSDE]: tokenConfig[TokenId.SUSDE],
  [CollateralId.LBTC]: tokenConfig[TokenId.LBTC],
  [CollateralId.CBBTC]: tokenConfig[TokenId.CBBTC],
  [CollateralId.EBTC]: tokenConfig[TokenId.EBTC],
  [CollateralId.OP]: tokenConfig[TokenId.OP],
  [CollateralId.DEUSD]: tokenConfig[TokenId.DEUSD],
  [CollateralId.SOLVBTC]: tokenConfig[TokenId.SOLVBTC],
  [CollateralId.SOLVBTCBBN]: tokenConfig[TokenId.SOLVBTCBBN],
  [CollateralId.DRV]: tokenConfig[TokenId.DRV],
  [CollateralId.STDRV]: tokenConfig[TokenId.STDRV],
}

export type DepositTokenConfig = {
  symbol: string
  name: string
  isDollarFormat: boolean
  decimals: number
  tokenConfig: TokenConfig
  isActive: boolean
  hasApy?: boolean
  allowlist?: Set<Address>
}

export const depositTokenConfig: Record<DepositTokenId, DepositTokenConfig> = {
  [DepositTokenId.WETH]: {
    symbol: 'WETH',
    name: 'Wrapped Ether',
    isDollarFormat: false,
    decimals: WEI_DECIMALS,
    tokenConfig: tokenConfig[TokenId.ETH],
    isActive: true,
  },
  [DepositTokenId.ETH]: {
    symbol: 'ETH',
    name: 'Ethereum',
    isDollarFormat: false,
    decimals: WEI_DECIMALS,
    tokenConfig: tokenConfig[TokenId.ETH],
    isActive: true,
  },
  [DepositTokenId.USDC]: {
    symbol: 'USDC',
    name: 'USD Coin',
    isDollarFormat: true,
    decimals: USDC_DECIMALS,
    tokenConfig: tokenConfig[TokenId.USDC],
    isActive: true,
  },
  [DepositTokenId.USDCe]: {
    symbol: 'USDC.e',
    name: 'USD Coin Bridged',
    isDollarFormat: true,
    decimals: USDC_DECIMALS,
    tokenConfig: tokenConfig[TokenId.USDC],
    isActive: true,
  },
  [DepositTokenId.WBTC]: {
    symbol: 'WBTC',
    name: 'Wrapped BTC',
    isDollarFormat: false,
    decimals: WBTC_DECIMALS,
    tokenConfig: tokenConfig[TokenId.WBTC],
    isActive: true,
  },
  [DepositTokenId.USDT]: {
    symbol: 'USDT',
    name: 'Tether USDt',
    isDollarFormat: true,
    decimals: USDT_DECIMALS,
    tokenConfig: tokenConfig[TokenId.USDT],
    isActive: true,
  },
  [DepositTokenId.WSTETH]: {
    symbol: 'wstETH',
    name: 'Wrapped Staked ETH',
    isDollarFormat: false,
    decimals: WEI_DECIMALS,
    tokenConfig: tokenConfig[TokenId.WSTETH],
    isActive: true,
    hasApy: true,
  },
  [DepositTokenId.WEETH]: {
    symbol: 'weETH',
    name: 'Wrapped eETH',
    isDollarFormat: false,
    decimals: WEI_DECIMALS,
    tokenConfig: tokenConfig[TokenId.WEETH],
    isActive: true,
  },
  [DepositTokenId.RSWETH]: {
    symbol: 'rswETH',
    name: 'Restaked Swell ETH',
    isDollarFormat: false,
    decimals: WEI_DECIMALS,
    tokenConfig: tokenConfig[TokenId.RSWETH],
    isActive: true,
  },
  [DepositTokenId.RSETH]: {
    symbol: 'rsETH',
    name: 'Kelp Restaked ETH',
    isDollarFormat: false,
    decimals: WEI_DECIMALS,
    tokenConfig: tokenConfig[TokenId.RSETH],
    isActive: true,
  },
  [DepositTokenId.DAI]: {
    decimals: 18,
    symbol: 'DAI',
    name: 'DAI',
    isDollarFormat: true,
    tokenConfig: tokenConfig[TokenId.DAI],
    isActive: true,
  },
  [DepositTokenId.SDAI]: {
    decimals: 18,
    symbol: 'sDAI',
    name: 'Savings Dai',
    isDollarFormat: false,
    tokenConfig: tokenConfig[TokenId.SDAI],
    isActive: true,
  },
  [DepositTokenId.USDE]: {
    decimals: 18,
    symbol: 'USDe',
    name: 'Ethena USDe',
    isDollarFormat: true,
    tokenConfig: tokenConfig[TokenId.USDE],
    isActive: true,
  },
  [DepositTokenId.SUSDE]: {
    decimals: 18,
    symbol: 'sUSDe',
    name: 'Ethena Staked USDe',
    isDollarFormat: false,
    tokenConfig: tokenConfig[TokenId.SUSDE],
    isActive: true,
  },
  [DepositTokenId.LBTC]: {
    decimals: 8,
    symbol: 'LBTC',
    name: 'Lombard Staked Bitcoin',
    isDollarFormat: false,
    tokenConfig: tokenConfig[TokenId.LBTC],
    isActive: true,
  },
  [DepositTokenId.CBBTC]: {
    decimals: 8,
    symbol: 'cbBTC',
    name: 'Coinbase Wrapped BTC',
    isDollarFormat: false,
    tokenConfig: tokenConfig[TokenId.CBBTC],
    isActive: true,
  },
  [DepositTokenId.EBTC]: {
    decimals: 8,
    symbol: 'eBTC',
    name: 'Ether.fi Bitcoin',
    isDollarFormat: false,
    tokenConfig: tokenConfig[TokenId.EBTC],
    isActive: true,
    allowlist: EBTC_DEPOSIT_ADDRESS_ALLOWLIST,
  },
  [DepositTokenId.OP]: {
    decimals: WEI_DECIMALS,
    symbol: 'OP',
    name: 'Optimism',
    isDollarFormat: false,
    tokenConfig: tokenConfig[TokenId.OP],
    isActive: true,
  },
  [DepositTokenId.DEUSD]: {
    decimals: WEI_DECIMALS,
    symbol: 'deUSD',
    name: 'Elixir deUSD',
    isDollarFormat: true,
    tokenConfig: tokenConfig[TokenId.DEUSD],
    isActive: false,
  },
  [DepositTokenId.SOLVBTC]: {
    decimals: WEI_DECIMALS,
    symbol: 'SolvBTC',
    name: 'Solv BTC',
    isDollarFormat: false,
    tokenConfig: tokenConfig[TokenId.SOLVBTC],
    isActive: true,
  },
  [DepositTokenId.SOLVBTCBBN]: {
    decimals: WEI_DECIMALS,
    symbol: 'SolvBTCBBN',
    name: 'Solv BTC Babylon',
    isDollarFormat: false,
    tokenConfig: tokenConfig[TokenId.SOLVBTCBBN],
    isActive: true,
  },
  [DepositTokenId.DRV]: {
    decimals: WEI_DECIMALS,
    symbol: 'DRV',
    name: 'Derive',
    isDollarFormat: false,
    tokenConfig: tokenConfig[TokenId.DRV],
    isActive: true,
  },
}

export type WithdrawNetworkToken = {
  network: DepositNetwork
  token: TokenId
  withdrawToken: DepositTokenId
}

export const MINT_TESTNET_USDC_AMOUNT = BigInt(100_000_000000) // 100k USDC minted

// Note: this is a mock address for native ETH
export const ETH_ADDRESS: Address = '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE'

export type DepositTokenAddresses = Record<string, Record<DepositTokenId, Address | undefined>>
export type TokenAddresses = Record<TokenId, Address | undefined>

export const DEFAULT_WITHDRAW_NETWORK_TOKEN: WithdrawNetworkToken = {
  network: DepositNetwork.Ethereum,
  token: TokenId.USDC,
  withdrawToken: DepositTokenId.USDC,
}

export const EMPTY_COLLATERALS: Record<CollateralId, Collateral> = Object.values(
  CollateralId
).reduce(
  (acc, collateralId) => {
    const collatData: Collateral = {
      ...collateralConfig[collateralId],
      spotPrice: 0,
      spotPrice24hAgo: 0,
      spotPrice24hChange: 0,
      spotPrice24hPctChange: 0,
    }
    acc[collateralId] = collatData
    return acc
  },
  {} as Record<CollateralId, Collateral>
)

export const getEmptyCollaterals = <T>(emptyValue: T): Record<CollateralId, T> =>
  Object.values(CollateralId).reduce(
    (acc, token) => {
      acc[token] = emptyValue
      return acc
    },
    {} as Record<CollateralId, T>
  )

export const DRV_TOKEN_ADDRESS = isTestnet
  ? '0x3fbfd80ef7591658d1d7ddec067f413efd6f985c'
  : '0x2EE0fd70756EDC663AcC9676658A1497C247693A'
export const STDRV_TOKEN_ADDRESS = isTestnet
  ? '0xbE9DBda519e15a1c0d238cEa0b3daD47a484A6Ff'
  : '0x7499d654422023a407d92e1D83D387d81BC68De1'
